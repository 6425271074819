:root {
  --experience-html-skillbarlength: 90%;
  --experience-css-skillbarlength: 80%;
  --experience-js-skillbarlength: 70%;
  --experience-php-skillbarlength: 70%;
  --experience-sql-skillbarlength: 60%;
  --experience-wp-skillbarlength: 40%;
  --experience-react-skillbarlength: 50%;
}

.experience {
  background-color: #404040;
  color: white;
  padding-bottom: 90px;
}

.experience__title {
  font-size: 3em;
  margin: 0;
  padding-bottom: 40px;
  text-align: center;
}

.experience__skillbars {
  width: 750px;
  margin: auto;
}

.experience__section--title {
  font-size: 16px;
}

.experience__skillbar {
  display: grid;
  grid-template-columns: repeat(10, 75px);
}

.experience__skillbar * {
  width: 75px;
  height: 10px;
  border-style: solid;
  border-color: black;
  border-width: 2.5px 1.5px 2.5px 1.5px;
  z-index: 1;
}

.experience__skillbar div:nth-child(1) {
  border-radius: 20px 0 0 20px;
  border-left-width: 2px;
}

.experience__skillbar div:nth-child(10) {
  border-radius: 0 20px 20px 0;
  border-right-width: 2px;
}

.experience__skillbar--bar {
  position: absolute;
  height: 10px;
  width: 750px;
  margin-top: 2px;
}

.experience__skillbar--bar .experience__skillbar--bar-score {
  background-color: green;
  height: 10px;
  border-radius: 20px;
  width: 100%;
  animation: skillBar 1s linear;
}

.experience__skillbar--bar
  .experience__skillbar--bar-score.experience__skillbar--bar-inexperienced {
  background-color: orange;
}

.experience__skillbar--bar .experience__skillbar--bar-hidden-score {
  position: absolute;
  top: 0;
  right: -2px;
  background-color: #404040;
  height: 10px;
  border-radius: 0 20px 20px 0;
}

@media screen and (max-width: 600px) {
  .experience__skillbars {
    width: 95%;
  }

  .experience__skillbar {
    display: grid;
    grid-template-columns: repeat(10, 35px);
  }

  .experience__skillbar * {
    width: 35px;
  }

  .experience__skillbar--bar {
    width: 350px;
  }
}

@media screen and (max-width: 750px) {
  .experience__skillbars {
    width: 95%;
  }

  .experience__skillbar {
    display: grid;
    grid-template-columns: repeat(10, 50px);
  }

  .experience__skillbar * {
    width: 50px;
  }

  .experience__skillbar--bar {
    width: 500px;
  }
}

@keyframes skillBar {
  from {
    width: 0;
    border-radius: 20px 0 0 20px;
  }
  to {
    width: 100%;
  }
}
