.nav {
  margin: 0;
  position: sticky;
  top: 0;
  z-index: 2;
}

.nav__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.nav__list--item {
  display: block;
  padding: 1em 1em;
  color: white;
  background-color: #404040;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.5s, border 0.5s;
}

.nav__list--item:hover {
  background-color: #333333;
}

@media screen and (max-width: 600px) {
  .nav__list--item {
    font-size: 80%;
  }
}
