.modalWindow__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  display: grid;
  justify-items: center;
  align-items: center;
}

.modalWindow__create {
  max-width: 80%;
  padding: 0 2em 2em 2em;
  border-radius: 1em;
  background-color: white;
  max-height: 80vh;
  overflow-y: auto;
  grid-row-gap: 1em;
  animation: create 0.3s;
}

.modalWindow {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr auto;
}

.modalWindow div:nth-child(1) {
  grid-column: 1/1;
  grid-row: 1/1;
}

.modalWindow div:nth-child(2) {
  grid-column: 1/3;
  grid-row: 2/2;
}

.modalWindow div:nth-child(3) {
  grid-column: 2/2;
  grid-row: 1/1;
}

@keyframes create {
  0% {
    opacity: 0;
    margin-top: 100px;
  }
  10% {
    opacity: 0.05;
    margin-top: 90px;
  }
  20% {
    opacity: 0.1;
    margin-top: 80px;
  }
  30% {
    opacity: 0.15;
    margin-top: 70px;
  }
  40% {
    opacity: 0.2;
    margin-top: 60px;
  }
  50% {
    opacity: 0.25;
    margin-top: 50px;
  }
  60% {
    opacity: 0.3;
    margin-top: 40px;
  }
  70% {
    opacity: 0.4;
    margin-top: 30px;
  }
  80% {
    opacity: 0.5;
    margin-top: 20px;
  }
  90% {
    opacity: 0.6;
    margin-top: 10px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

.modalWindow__cross {
  width: 2em;
  justify-self: end;
  font-size: 2em;
  font-weight: 900;
  transform: translateX(1em);
  border: 0;
  position: relative;
}

.modalWindow__cross:hover {
  color: red;
}
