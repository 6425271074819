.contact {
  background-color: #404040;
  color: white;
}

.contact__title {
  margin: 0;
  text-align: center;
  font-size: 3em;
}

.contact__text {
  width: 350px;
  margin: 20px auto;
}

.contact__buttons {
  padding-bottom: 40px;
  text-align: center;
}

.experience__download--link {
  margin: 0 5px;
  padding: 15px 30px;
  font-size: 1.2em;
  background-color: black;
  border: solid 3px #404040;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.5s, border 0.5s;
}

.experience__download--link:hover {
  background-color: #262626;
  border: solid 3px #404040;
}

.contact__informatie--spans {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 10px;
}

.contact__informatie--icon {
  height: 1em;
  margin: 0 5px 0 0;
}

.contact__informatie--link {
  color: black;
  text-decoration: underline dotted;
}

.contact__formulier--input {
  padding: 10px;
}

.contact__formulier--textarea {
  margin: 3px 0;
  padding: 10px;
}

.contact__formulier--submit {
  cursor: pointer;
  background-color: #99ccff;
  border: solid 2px #1a8cff;
  border-radius: 20px;
  padding: 5px 10px;
  transition: background-color 0.5s, border 0.5s;
}

.contact__formulier--submit:hover {
  background-color: #1a8cff;
  border: solid 2px #0066cc;
}
