.internships {
  width: 95%;
  margin: 0 auto;
}

.internships__title {
  font-size: 3em;
  text-align: center;
}

.internships__list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.internships__intership {
  border: solid black 1px;
  border-radius: 12px;
  padding: 10px;
}

.internships__intership--name {
  margin-top: 0;
}

.internships__intership--tasks {
  margin: 0;
}

@media screen and (max-width: 992px) {
  .internships__list {
    grid-template: 1fr 1fr / 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .internships__list {
    grid-template: 1fr 1fr 1fr / 1fr;
  }
}
