html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
}

.aboutMe,
.internships,
.experience,
.myWork,
.contact {
  padding: 90px 0;
}
