.aboutMe__title {
  font-size: 3em;
  text-align: center;
}

.aboutMe__texts {
  width: 50%;
  margin: 0 auto;
}

.aboutMe__text {
  font-size: 1.5em;
}

.aboutMe__text:nth-of-type(1) {
  font-size: 1.6em;
  font-weight: bold;
}

.aboutMe__download {
  text-align: center;
}

.aboutMe__download--link {
  margin: 0 5px;
  padding: 15px 30px;
  font-size: 1.2em;
  color: white;
  text-decoration: none;
  background-color: #004d99;
  border: solid 3px #00134d;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.5s, border 0.5s;
}

.aboutMe__download--link:hover {
  border: solid 3px #004d99;
  background-color: #0080ff;
}

@media screen and (max-width: 600px) {
  .aboutMe__texts {
    width: 95%;
  }
}
