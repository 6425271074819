.myWork {
  margin-bottom: 70px;
}

.myWork__title {
  margin: 0 0 100px 0;
  text-align: center;
  font-size: 3em;
}

.myWork--grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

.myWork__project {
  background-color: white;
  color: black;
  margin: 10px;
  padding: 20px;
  border: solid 3px black;
  border-radius: 20px;
}

.myWork__project--inside {
  margin: 20px 40px;
}

.myWork__project--inside_title {
  text-align: center;
  font-size: 2em;
}

.myWork__project--inside_img {
  width: 100%;
}

.myWork__project--inside_text {
  font-size: 1.2em;
}

.myWork__project--inside_button_margin {
  width: 120px;
  margin: 0 auto;
}

.myWork__project--inside_button {
  cursor: pointer;
  color: white;
  background-color: black;
  border: solid 3px #404040;
  border-radius: 20px;
  padding: 5px 10px;
  transition: background-color 0.5s, border 0.5s;
}

.myWork__project--inside_button:hover {
  background-color: #262626;
  border: solid 3px #404040;
}

.myWork__project--modal-grid {
  display: grid;
  grid-template-columns: auto auto;
}

.myWork__project--image {
  max-width: 100%;
}

.myWork__project--text {
  max-width: 80%;
  margin: 0 auto;
}

.myWork__project--icons {
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.myWork__project--icons a {
  margin: auto;
}

.myWork__project--icons img {
  height: 50px;
}

.myWork__project--icons .website {
  grid-column: 3/3;
}

@media screen and (max-width: 992px) {
  .myWork--grid {
    grid-template: 1fr 1fr / 1fr 1fr;
  }
}

@media screen and (max-width: 600px) {
  .myWork__project--modal-grid {
    grid-template-columns: auto;
  }

  .myWork__project--icons {
    grid-column: 1/1;
  }
}

@media screen and (max-width: 600px) {
  .myWork--grid {
    grid-template: 1fr 1fr 1fr / 1fr;
  }
}
