.home {
  height: 100vh;
  opacity: 0.9;
  background-size: cover;
}

.home__titles {
  padding-top: 35vh;
}

.home__titles * {
  color: white;
  text-shadow: 2px 2px 10px grey;
  text-align: center;
}

.home__title--1 {
  font-size: 7em;
  margin-top: 0;
  margin-bottom: 20px;
}

.home__title--2 {
  margin: 0;
  font-size: 3.5em;
}

@media screen and (max-width: 600px) {
  .home__title--1 {
    font-size: 6em;
  }

  .home__title--2 {
    font-size: 3em;
  }
}
